/* eslint-disable camelcase */

import AreaguideInfo, {
  AreaguideMoreInfo,
} from "@components/AreaguideInfo/AreaguideInfo"
import BannerLanding from "@components/Banner/BannerLanding"
import loadable from "@loadable/component"

// import FeaturedPropertiesSlider from "@components/FeaturedPropertiesSlider/FeaturedPropertiesSlider"
import Layout from "@components/Layout/Layout"

import { usePropertyStore } from "@components/SearchResults/store/propertyStore"
import Seo from "@components/seo"
import { aboutURL, communitiesURL, propertiesSaleURL } from "@lib/urls"
import { graphql, navigate } from "gatsby"
import { isEmpty } from "lodash"
import React, { useEffect } from "react"
import { Container } from "react-bootstrap"
import areas from './../../static/areas.json';
import FeaturedPropertiesSlider from "@components/FeaturedPropertiesSlider/FeaturedPropertiesSlider"
import AreaguideFeaturedPropertiesSlider from "@components/AreaguideFeaturedPropertiesSlider/AreaguideFeaturedPropertiesSlider"
import AreaguideProperties from "@components/DubaiCommunities/AreaguideProperties"
import DubaiCommunities from "@components/DubaiCommunities/DubaiCommunities"
import CommunityTeamsSlider from "@components/TeamsSlider/CommunityTeamsSlider"
import SponsoredProjects from "@components/SponsoredProjects/SponsoredProjects"
import BContactFomModule from "@components/ContactFomModule/BContactFomModule"
const VideoAskWidget = loadable(() => import("@components/VideoAsk/VideoAsk"))
const ContactFomModule = loadable(() => import("@components/ContactFomModule/ContactFomModule"))
const SingleMap = loadable(() => import("@components/SearchResults/map/SingleMap"))
function AreaguideDetailTemplate({ data }) {
  const areaguide = data?.strapiAreaGuide
  const propertiesByArea = data?.propertiesByArea?.nodes || []
  const propertiesByAddress2 = data?.propertiesByAddress2?.nodes || []
  const propertiesByAddress3 = data?.propertiesByAddress3?.nodes || []
  const propertiesByCustomSlug = data?.propertiesByCustomSlug?.nodes || []
  // const propertiesByAreaRegex = data?.propertiesByAreaRegex?.nodes || []
  // const propertiesByAddress2Regex = data?.propertiesByAddress2Regex?.nodes || []
  // const propertiesByAddress3Regex = data?.propertiesByAddress3Regex?.nodes || []

  const properties = [
    ...propertiesByArea,
    ...propertiesByAddress2,
    ...propertiesByAddress3,
    ...propertiesByCustomSlug,
    // ...propertiesByAreaRegex,
    // ...propertiesByAddress2Regex,
    // ...propertiesByAddress3Regex,
  ]

  // remove duplicates
  const uniqueProperties = properties

  // const services = data?.strapiGlobalModule?.community_related_content
  const offplans = data?.off_plans?.nodes || []

  const setSelectedAreas = usePropertyStore((state) => state.setSelectedAreas)

  useEffect(() => {
    setSelectedAreas([])
    const area = areas.find((jsonArea) => jsonArea?.slug === areaguide?.slug)
    const area1 = areas.find((jsonArea) => jsonArea?.slug === areaguide?.custom_property_slug)
    if (!isEmpty(area)) {
      setSelectedAreas([area])
    }
    if (!isEmpty(area1)) {
      setSelectedAreas([area1])
    }

  }, [])

//   // if (isEmpty(areaguide)) return navigate("/404/")

//   const offplans = offplansdata?.areaGuides?.data?.length > 0 && offplansdata?.areaGuides?.data[0]?.attributes?.off_plans?.data || []

//   const offpl = offplans
//   ?.filter((item) => item.attributes) // Filter out items without `attributes`
//   ?.map(({ attributes, ...rest }) => ({
//     ...rest,      // Spread the rest of the properties
//     ...attributes // Spread the `attributes` properties into the result
//   }));

//   console.log(offpl)
  const {
    ggfx_results,
    strapi_id: strapiID,
    title,
    banner_image,
    description,
    teams,
    video_ask_url,
    sponsored_projects

  } = areaguide

  const banner = {
    title: `${title} Guide`,
    description,
    banner_image,
    show_search: true,
    cta_text: {
      text: "Find a tenant or buyer in a jiffy",
      cta: {
        cta_label: "Sell or Lease your property",
        custom_link: propertiesSaleURL,
      },
    },
  }

  const breadcrumbURLs = [
    {
      url: communitiesURL,
      label: "Communities",
    },
    {
      url: "",
      label: title,
    },
  ]
  useEffect(() => {
    if (sessionStorage.getItem("searchpath") && typeof window !== "undefined") {
      sessionStorage.removeItem("searchpath");
    }
  }, []);
  const randomIndex = sponsored_projects?.length > 0
    ? Math.floor(Math.random() * sponsored_projects?.length)
    : areaguide?.off_plans?.length > 0
      ? Math.floor(Math.random() * areaguide?.off_plans?.length)
      : 0;

  const randomProject = sponsored_projects?.length > 0
    ? sponsored_projects[randomIndex]
    : areaguide?.off_plans?.length > 0
      ? areaguide?.off_plans[randomIndex]
      : [];


  return (
    <Layout
      pageData={{
        page_cnlass: "community-detail-page",
        layout: "community-detail",
      }}
    >
      <BannerLanding
        bannerData={banner}
        ggfx_results={ggfx_results}
        strapiID={strapiID}
        breadcrumbURLs={breadcrumbURLs}
        office={areaguide}
        isGuide
      />
      {/* {video_ask_url &&
<VideoAskWidget video_ask_url={video_ask_url} />
} */}
      <AreaguideInfo areaguide={areaguide} />


      {(!isEmpty(uniqueProperties)) && (
        <FeaturedPropertiesSlider
          module={{
            title: `Properties for sale in ${title}`,
            cta_text: {
              text: "View all properties for sale, for rent, or off plan projects.",
              cta: {
                cta_label: "View more properties",
                custom_link: propertiesSaleURL,
              },
            },
          }}
          properties={uniqueProperties}
          // offplans={offplans}
          // areaguidePage
          activekey={"for-sale"}
          hideoffplan
          morelink={areaguide?.custom_property_slug || title?.toLowerCase()?.replace(/ /g, "-")}

        />
      )}
      {Object.entries(randomProject).length !== 0 && <Container>
        <SponsoredProjects minsBeds={randomProject.min_bedrooms}
          maxBeds={randomProject.max_bedrooms}
          displayBedrooms={randomProject.display_bedrooms}
          buldingType={randomProject.building_type}
          image={randomProject?.ads_image ? randomProject?.ads_image : randomProject.banner_image ? randomProject.banner_image : randomProject?.media_images?.length > 0 ? randomProject?.media_images[0] : randomProject?.images?.length > 0 ? randomProject?.images[0] : ""}
          title={randomProject.title}
          developer={randomProject.developer}
          location={randomProject.display_address}
          slug={randomProject.slug}
          videoURL={randomProject?.video_module?.video_url}
          community={randomProject?.community?.title}
          key={randomProject.id}
          completionYear={randomProject.completion_year}
          price={randomProject?.price}
          listView={true}
          description={randomProject?.about?.data}
          future_launch={randomProject?.future_launch}
          ggfx_results={randomProject?.ggfx_results}
          strapi_id={randomProject?.strapi_id}
          property={randomProject}
          ads_mobile_image={randomProject?.ads_mobile_image ? randomProject?.ads_mobile_image : ""}
        />
      </Container>}
      {(!isEmpty(offplans)) && (
        <AreaguideFeaturedPropertiesSlider
          module={{
            title: `Off plan projects for sale in ${title}`,
            cta_text: {
              text: "View all properties for sale, for rent, or off plan projects.",
              cta: {
                cta_label: "View more properties",
                custom_link: propertiesSaleURL,
              },
            },
          }}
          // properties={uniqueProperties}
          offplans={offplans}
          // areaguidePage
          activekey={"off-plan"}
          morelink={areaguide?.custom_property_slug || title?.toLowerCase()?.replace(/ /g, "-")}
        />
      )}
      <CommunityTeamsSlider heading={`Contact Property Expert in ${title}`} area={title} teams={!isEmpty(teams) && areaguide.teams} />

      {/* {!isEmpty(teams) && (
        <TeamsSlider
          teams={areaguide.teams}
          module={{
            title: `Meet the ${title} team`,
          }}
        />
      )} */}
      <AreaguideMoreInfo
        more_info={areaguide.more_info}
        title={areaguide.title}
      />

      <SingleMap lat={areaguide.latitude} lng={areaguide.longitude} />
      {/* <OurServices
        module={services}
        imagetransforms={imagetransforms}
        strapiID={strapiID}
      /> */}

      <AreaguideProperties areaguide={areaguide} />
      {areaguide?.masterplan_image?.url ?

        <BContactFomModule module={{
          heading: "Speak with our Real Estate specialists today",
          title:
            "Get in touch for tailored guidance from our expert team. We’re committed to assisting you through each phase of your journey.",
        }} masterplan_image={areaguide?.masterplan_image} ggfx_results={ggfx_results}
          strapiID={strapiID}
        />

        :
        <ContactFomModule
          module={{
            heading: "Speak with our Real Estate specialists today",
            title:
              "Get in touch for tailored guidance from our expert team. We’re committed to assisting you through each phase of your journey.",
          }}
        />}
      <DubaiCommunities
        module={{
          title: "Properties in Dubai Communities",
        }}
      />

    </Layout>
  )
}

export default AreaguideDetailTemplate

export const Head = ({ data }) => {
  const pageData = data?.strapiAreaGuide
  const images = pageData?.ggfx_results
  const findImageBySize = (size) => {
    if (!Array.isArray(images)) {
      // If images is not an array, return null or handle the error accordingly
      return null;
    }

    for (let image of images) {
      if (!image?.transforms || image?.transforms?.length === 0) continue;
      for (let transform of image.transforms) {
        if (transform?.transform === size) {
          return transform?.url; // Return the URL if the size matches
        }
      }
    }
    return null; // Return null if no image with the desired size is found
  };

  const imageUrl = findImageBySize("744x");
  return (
    <Seo
      title={pageData?.seo?.metaTitle || `${pageData?.title} Area Guide`}
      description={pageData?.seo?.metaDescription || `Find your perfect home in ${pageData?.title} with Provident Estate. Get an in-depth look at this exclusive Dubai community, including available properties, amenities, and lifestyle advantages.`}
      customCanonical={pageData?.seo?.canonicalURL}
      img={imageUrl ? imageUrl : pageData?.banner_image?.url}
    />
  )
}

export const query = graphql`
  query ($id: String, $title: String, $regex: String, $custom_property_slug: String) {
    strapiAreaGuide(id: { eq: $id }) {
      ...areaguideFragment
    }

    off_plans : allProjects(
        limit: 6
        filter: {community: {title: {eq: $title}}, publish: {eq: true}}
        sort: { fields: rank, order: ASC }
      ) {
        nodes {
          ...offplanCardFragment
        }
      }

    propertiesByArea: allProperties(
      limit: 6
      sort: { fields: createdAt, order: DESC }
      filter: { area: { eq: $title }, publish: { eq: true } }
    ) {
      nodes {
        ...propertyCardFragment
      }
    }

    propertiesByCustomSlug: allProperties(
      limit: 6
      sort: { fields: createdAt, order: DESC }
      filter: { slug: { glob: $custom_property_slug }, publish: { eq: true } }
    ) {
      nodes {
        ...propertyCardFragment
      }
    }

    propertiesByAddress2: allProperties(
          limit: 6
      sort: { fields: createdAt, order: DESC }
      filter: { address: { address2: { eq: $title } }, publish: { eq: true } }
    ) {
      nodes {
        ...propertyCardFragment
      }
    }

    propertiesByAddress3: allProperties(
          limit: 6
      sort: { fields: createdAt, order: DESC }
      filter: { address: { address3: { eq: $title } }, publish: { eq: true } }
    ) {
      nodes {
        ...propertyCardFragment
      }
    }

    propertiesByAreaRegex: allProperties(
          limit: 6
      sort: { fields: createdAt, order: DESC }
      filter: { area: { regex: $regex }, publish: { eq: true } }
    ) {
      nodes {
        ...propertyCardFragment
      }
    }

    propertiesByAddress2Regex: allProperties(
          limit: 6
      sort: { fields: createdAt, order: DESC }
      filter: {
        address: { address2: { regex: $regex } }
        publish: { eq: true }
      }
    ) {
      nodes {
        ...propertyCardFragment
      }
    }

    propertiesByAddress3Regex: allProperties(
          limit: 6
      sort: { fields: createdAt, order: DESC }
      filter: {
        address: { address3: { regex: $regex } }
        publish: { eq: true }
      }
    ) {
      nodes {
        ...propertyCardFragment
      }
    }

    strapiGlobalModule {
      community_related_content {
        ...ourServices
      }
    }
  }
`
